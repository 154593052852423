import React from "react";
import { ReactComponent as Developersvg } from "../images/developer_activity.svg";

const Aboutme = () => {
  return (
    <div className="grid grid-cols-1 sm:mt-10 m-3 sm:grid-cols-2 gap-4 select-none ">
      
      {/* Left Section: Introduction */}
      <div className="flex flex-col gap-5 ">
        <h1 className="text-xl sm:text-3xl md:text-4xl lg:text-5xl font-sans font-semibold cursor-pointer">
          <span className="text-green-400 underline font-bold">
            I<span className="text-white">ntro</span>
          </span>
          duction
        </h1>
        <p>
          Hi, I’m{" "}
          <span className="font-bold underline font-serif hover:text-green-400 cursor-pointer">
            Raj Poudel
          </span>
          , a passionate web developer skilled in creating innovative and
          user-centric web applications. With a solid foundation in modern
          JavaScript frameworks and backend technologies, I aim to deliver
          scalable solutions that enhance user experiences.
        </p>

        <h1 className="text-xl sm:text-3xl md:text-4xl lg:text-5xl font-sans font-semibold cursor-pointer">
          <span className="text-green-400 underline font-bold">
            Ab<span className="text-white">o</span>
          </span>
          ut Me
        </h1>
        <ul className="list-disc pl-5">
          <li>
            <strong>Education:</strong> Bachelor of Science in CSIT (Ongoing) –
            Patan Multiple Campus
          </li>
          <li>Completed Science (+2) from Kathmandu Model Secondary School</li>
          <li>
        Skilled in React, Node.js, MongoDB, Bootstrap, and Tailwind CSS
      </li>
      <li>
        Passionate about building user-friendly and scalable web
        applications
      </li>
        </ul>
      </div>

      {/* Right Section: Additional Content */}
      <div className=" animate-pulse">
        <Developersvg className="w-full h-auto cursor-pointer"  />
      </div>
    </div>
  );
};

export default Aboutme;

//  <div>
//   <p>
//     I strive to craft dynamic web applications while constantly learning
//     modern tools and frameworks. Beyond coding, I enjoy exploring UI/UX
//     design and contributing to collaborative projects. Feel free to
//     connect to learn more about my work and journey!
//   </p>
// </div> 
