import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Change navbar appearance when scrolling
      if (window.scrollY > 10) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={`fixed top-0 left-0 w-full z-50 transition-all duration-300 ${
        isScrolled ? "bg-black bg-opacity-35 shadow-lg" : "bg-transparent"
      }`}
    >
      <div id="navbar" className="container mx-auto flex flex-wrap p-5 items-center justify-between">
        <AnchorLink href="#home" className="text-xl hover:text-green-300 cursor-pointer font-bold">
          &lt;/&gt;
        </AnchorLink>

        {/* Mobile Menu Toggle */}
        <button
          className="md:hidden inline-flex items-center border-0 py-1 px-3 focus:outline-none hover:bg-gray-500 rounded text-base"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        >
          <svg
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            className="w-6 h-6"
            viewBox="0 0 24 24"
          >
            <path d="M4 6h16M4 12h16m-7 6h7"></path>
          </svg>
        </button>

        {/* Desktop Menu */}
        <nav className="hidden md:flex md:ml-auto flex-wrap items-center text-lg justify-center">
          <AnchorLink href="#home" className="mr-5 hover:text-gray-500 cursor-pointer">
            Home/&gt;
          </AnchorLink>
          <AnchorLink href="#aboutme" className="mr-5 hover:text-gray-500 cursor-pointer">
            About me/&gt;
          </AnchorLink>
          <AnchorLink href="#skills" className="mr-5 hover:text-gray-500 cursor-pointer">
            Skills/&gt;
          </AnchorLink>
          <AnchorLink href="#projects" className="mr-5 hover:text-gray-500 cursor-pointer">
            Project/&gt;
          </AnchorLink>
          <AnchorLink href="#contactme" className="mr-5 hover:text-gray-500 cursor-pointer">
            Contact me/&gt;
          </AnchorLink>
        </nav>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <nav className="flex flex-col items-end mr-7 mt-4 space-y-3 md:hidden">
          <AnchorLink href="#home" className="hover:text-gray-500">
            Home
          </AnchorLink>
          <AnchorLink href="#aboutme" className="hover:text-gray-500">
            About me
          </AnchorLink>
          <AnchorLink href="#skills" className="hover:text-gray-500">
            Skills
          </AnchorLink>
          <AnchorLink href="#projects" className="hover:text-gray-500">
            Project
          </AnchorLink>
          <AnchorLink href="#contactme" className="hover:text-gray-500">
            Contact me
          </AnchorLink>
        </nav>
      )}
    </header>
  );
};

export default Navbar;
