import React, { useEffect, useRef } from "react";

import HomeImage from "../images/profile_home.jpg";

import Typed from "typed.js";

const Home = () => {
  const el = useRef(null); // Element to display the typed text
  const typed = useRef(null); // Store the Typed instance

  useEffect(() => {
    const options = {
      strings: [
        "Web Developer",
        "React.js ",
        "Web Designer",
        "Node.js Developer",
      ],
      typeSpeed: 150,
      backSpeed: 150,
      loop: true,
    };

    // Initialize Typed.js
    typed.current = new Typed(el.current, options);

    // Cleanup on unmount
    return () => {
      typed.current.destroy();
    };
  }, []);
  return (
    <>
    <div className="pt-3 mt-9 select-none flex flex-col  md:flex-row justify-between w-full md:w-[90%] mx-auto ">
      <img
        src={HomeImage}
        alt="Homeimage"
        className="rounded-xl cursor-pointer advanced-image w-[80%] sm:w-[60%] md:w-[450px] "
        style={{
          clipPath: `polygon(
            ${Math.random() * 20}% ${Math.random() * 20}%, 
            ${80 + Math.random() * 20}% ${Math.random() * 20}%, 
            ${80 + Math.random() * 20}% ${80 + Math.random() * 20}%, 
            ${Math.random() * 20}% ${80 + Math.random() * 20}%
          )`,
        }}
      />

      <div className="mt-4 self-center relative  ">
        <p className="font-mono ">Hello<span className="text-green-400">|</span>again</p>
        <h1 className="text-4xl sm:text-5xl   cursor-pointer md:text-7xl lg:text-9xl font-serif font-semibold ">
          R<span className="text-green-400">aj</span> <br />
          <span className="text-green-400">Pou</span>
          del
        </h1>
        <p className="text-sm cursor-default font-mono absolute">
          -<span ref={el}></span>
        </p>

      </div>
      

    </div>
    </>
  );
};

export default Home;
