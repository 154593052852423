import React from "react";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

const Skills = () => {
  const skills = [
    {
      name: "Currently Learning",
      description: "Technologies I am actively learning:",
      topics: [
        "React (Concepts, Performance Optimization)",
        "Node.js/Express.js (Backend Development)",
        "Mongoose (MongoDB ORM)",
      ],
    },
    {
      name: "Tech Stack",
      description: "Key technologies that I use in my projects:",
      topics: [
        "Languages: HTML, CSS, C, C++, JavaScript",
        "Frontend Frameworks & Libraries: Bootstrap, Tailwind CSS, React ",
        "Backend: Node.js, Express.js",
        "Databases: MongoDB",
        "Authentication: JWT",
        "Hosting: Netlify, Vercel",
      ],
    },
    {
      name: "Developer Skills",
      description: "Tools and practices that enhance my development workflow:",
      topics: [
        "VS Code (IDE)",
        "Version Control: Git, GitHub",
      ],
    },

    {
      name: "Tools and Testing",
      description: "Additional tools I worked with:",
      topics: [
        "OOPs Concepts",
        "Firebase",
        "Jest (Testing Framework)",
        "Redux",
        "Multer (File Upload Middleware)",
        "Socket.io (Real-time Communication)",
        "Formik (Form Handling)",
        "Yup (Schema Validation)",
        "Redis (In-memory Data Store)",
        "Cypress (UI Testing)",
        "Lighthouse (Performance Analysis)",
        "Postman (API Testing)", 
      ],
    },
  ];

  return (
    <div id="skills" className="m-4 md:m-1 select-none">
      <Swiper
        navigation={true}
        slidesPerView={1}
        loop={true}
        modules={[Navigation]}
      >
        {skills.map((skill, index) => (
          <SwiperSlide
            key={index}
            className="flex rounded-lg items-center justify-center bg-black"
          >
            <div className="w-full h-full flex flex-col items-center justify-center text-center text-white px-6 lg:px-20">
              <h2 className="text-4xl font-bold mb-4">{skill.name}</h2>
              <p className="text-lg mb-6">{skill.description}</p>
              <ul className="text-left space-y-2 list-disc list-inside  p-6 max-w-3xl">
                {skill.topics.map((topic, idx) => (
                  <li key={idx} className="text-md">
                    {topic}
                  </li>
                ))}
              </ul>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Skills;
