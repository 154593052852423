import React from "react";
// Import Swiper React components
import "swiper/css";
import "swiper/css/pagination";
import "./style.css";

import { Link } from "react-router-dom";

export default function SwiperComponent({ pName, pNo, pDescription, pLink }) {
  return (
    <div className="p-6 text-white text-center select-none">
      <h1 className="text-3xl font-bold mb-2 cursor-default">
        Project <span className="text-green-400">{pNo}</span>
      </h1>
      <h2 className="text-2xl mb-4 font-serif underline cursor-pointer decoration-green-400">
        {pName}
      </h2>
      <p>
        {pDescription} <br />
        <Link to={pLink} target="_blank" className="text-green-400">
          Click here
        </Link>{" "}
        for demo.
      </p>

      <div className="fixed bottom-0  right-0 p-4 text-end ">
        <Link
          target="_blank"
          to={"https://github.com/rajpoudell?tab=repositories"}
          className=""
        >
          <span className="text-green-400">M</span>
          ore <span className="text-green-400">P</span>rojects
          <span className="text-green-400"> &#8594;</span>
        </Link>
      </div>
    </div>
  );
}
