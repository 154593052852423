import { Route, Routes } from "react-router-dom";
import 'aos/dist/aos.css';

import Navbar from "./components/Navbar";

import "./index.css";
import Layout from "./pages/Layout";
import Aos from "aos";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div className=" text-gray-50 w-[100%] md:w-[90%] md:mx-auto select-none">
      <Navbar />
      <Routes>
        <Route path="/" element={<Layout />} />
      </Routes>
    </div>
  );
}

export default App;
