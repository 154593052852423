import React from 'react';

const ContactMe = () => {
  return (
    <div className="min-h-screen rounded-lg text-green-450 flex items-center justify-center p-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 w-full max-w-4xl bg-[rgba(0,0,0,0.12)] p-6 rounded-lg shadow-lg">
        {/* Contact Information */}
        <div className="space-y-6  ">
          <h2 className="text-2xl text-center font-semibold text-green-450">Contact Information</h2>
          <ul className="text-sm text-gray-200 space-y-4">
            <li>
              <strong > <span className='text-green-400'>E</span>mail:</strong>{' '}
              <a href="mailto:razpoudel7@gmail.com" className="text-green-450 hover:underline">
                razpoudel7@gmail.com
              </a>
            </li>
            <li>
              <strong><span className='text-green-400'>P</span>hone:</strong>{' '}
              <a href="tel:+9779846720011" className="text-green-450 hover:underline">
              +977 9846720011
              </a>
            </li>
            <li>
              <strong><span className='text-green-400'>A</span>ddress:</strong> Kathmandu,Nepal
            </li>
          </ul>
          <div>
            <h3 className="text-xl font-semibold text-green-450 mb-4"><span className='text-green-400'>F</span>ollow <span className='text-green-400'>M</span>e</h3>
            <div className="space-x-6">
              <a
                href="https://www.linkedin.com/in/raj-poudel-470235234/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-green-450 hover:text-green-500"
              >
                LinkedIn
              </a>
              <a
                href="https://twitter.com/yourhandle"
                target="_blank"
                rel="noopener noreferrer"
                className="text-green-450 hover:text-green-500"
              >
                Facebook
              </a>
              <a
                href="https://github.com/rajpoudell"
                target="_blank"
                rel="noopener noreferrer"
                className="text-green-450 hover:text-green-500"
              >
                GitHub
              </a>
            </div>
          </div>
        </div>

        {/* Get In Touch Form */}
        <div>
          <h2 className="text-2xl font-semibold text-green-450 text-center mb-6">Get In Touch</h2>
          <form>
            <div className="mb-4">
              <label htmlFor="name" className="block text-sm font-medium">
                Your Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="mt-1 block w-full px-4 py-2 text-white bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-green-450"
                placeholder="Your Name"
              />
            </div>

            <div className="mb-4">
              <label htmlFor="email" className="block text-sm font-medium">
                Your Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="mt-1 block w-full px-4 py-2 text-white bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-green-450"
                placeholder="Your Email"
              />
            </div>

            <div className="mb-6">
              <label htmlFor="message" className="block text-sm font-medium">
                Your Message
              </label>
              <textarea
                id="message"
                name="message"
                rows="4"
                className="mt-1 block w-full px-4 py-2 text-white bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-green-450"
                placeholder="Write your message here..."
              ></textarea>
            </div>

            <button
              type="submit"
              className="w-full bg-green-450 text-gray-200 hover:text-black font-semibold transition duration-300 ease-in-out py-2 px-4 rounded-md hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-450"
            >
              Send Message
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactMe;
