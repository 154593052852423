import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Mousewheel, Pagination } from "swiper/modules";

import SwiperComponent from "../components/SwiperComponent";

import backgroundImage1 from "../images/project.png";
import backgroundImage2 from "../images/project2.png";
import backgroundImage3 from "../images/project3.png";

export default function Projects() {
  const projects = {
    project1: {
      pName: "React Quiz App",
      pNo: "1",
      pDescription:
        "This website was made with React. In this Project, UseState is mostly being used for updating state with user interaction.",
      pLink: "https://quiz-with-raj.vercel.app/",
    },
    project2: {
      pName: "Add to Cart",
      pNo: "2",
      pDescription:
        "This website was made with React which is based on Firebase Realtime database management system in which we can store data. This data can be modified by the user itself. Add and Delete both can be done by the user. The modification done by the user is directly linked with the database so Data gets deleted both from the website and the database as well.",
      pLink: "https://add-to-cart.poudelraj.com.np/",
    },
    project3: {
      pName: "Expense Manager",
      pNo: "3",
      pDescription:
        "Expense Manager is a React-based web application for tracking daily expenses. Users can add expense items with their respective costs, view the total expenditure, and delete items. The app dynamically updates the total using React's state management (useState), providing a simple and efficient way to manage expenses directly in the browser.",

      pLink: "https://room.poudelraj.com.np/",
    },
  };

  return (
    <div className="m-4 md:m-1  rounded-lg">
      <Swiper
        direction={"vertical"}
        slidesPerView={1}
        spaceBetween={30}
        mousewheel={true}
        pagination={{
          clickable: true,
        }}
        style={{
          backgroundColor: "black",
        }}
        modules={[Mousewheel, Pagination]}
        className="mySwiper rounded-lg !bg-black w-full"
      >
        <SwiperSlide
          style={{
            backgroundImage: `linear-gradient(to bottom left ,rgba(0, 0, 0, 0.912), rgba(0, 0, 0, 0.8)), url(${backgroundImage1})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
          className="w-auto rounded-lg "
        >
          <SwiperComponent
            pName={projects.project1.pName}
            pNo={projects.project1.pNo}
            pDescription={projects.project1.pDescription}
            pLink={projects.project1.pLink}
          />
        </SwiperSlide>
        <SwiperSlide
          style={{
            backgroundImage: `linear-gradient(to bottom left ,rgba(0, 0, 0, 0.912), rgba(0, 0, 0, 0.8)), url(${backgroundImage2})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
          className="w-auto rounded-lg "
        >
          <SwiperComponent
            pName={projects.project2.pName}
            pNo={projects.project2.pNo}
            pDescription={projects.project2.pDescription}
            pLink={projects.project2.pLink}
          />
        </SwiperSlide>
        <SwiperSlide
          style={{
            backgroundImage: `linear-gradient(to bottom left ,rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${backgroundImage3})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
          className="w-auto rounded-lg "
        >
          <SwiperComponent
            pName={projects.project3.pName}
            pNo={projects.project3.pNo}
            pDescription={projects.project3.pDescription}
            pLink={projects.project3.pLink}
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
